export const FormMapper = {
  // All the "themes" are automagically generated from backend's
  // util.py::THEME_VISION_DICT.
  theme1: "LLMs with Limited Data",
  theme2: "Frontier of LLM Methods",
  theme3: "Multi-modal and Multi-lingual LLMs",
  theme4: "Value Alignment and Trustworthiness of Generative AI",
  theme5: "Training LLMs Without Sharing Data",

  nus: "National University of Singapore (NUS)",
  ntu: "Nanyang Technological University (NTU)",
  smu: "Singapore Management University (SMU)",
  suss: "Singapore University of Social Science (SUSS)",
  sutd: "Singapore University of Technology and Design (SUTD)",
  sit: "Singapore Institute of Technology (SIT)",
  inter: "International",
  "a-star": "Agency for Science, Technology and Research (A*STAR)",
  PI: "Principal Investigator",
  "Co-PI": "Co Principal Investigator",
  collab: "Collaborator",
};

export const SysMapper = {
  draft: "Draft",
  "complete-un": "Completed (Unendorsed)",
  "reject-cluster": "Rejected (Host Institution)",
  "complete-en": "Completed (Endorsed)",
  reject: "Rejected (AISG)",
  "revise-cluster": "Revision Request (Host Institution)",
  "revise-aisg-r": "Revision Request (AISG)",
  "revise-aisg-c": "Revision Completed (AISG)",
  proceed: "Proceed to Stage 2",
  awarded: "Awarded",
  nus: "National University of Singapore (NUS)",
  ntu: "Nanyang Technological University (NTU)",
  smu: "Singapore Management University (SMU)",
  suss: "Singapore University of Social Science (SUSS)",
  sutd: "Singapore University of Technology and Design (SUTD)",
  sit: "Singapore Institute of Technology (SIT)",
  inter: "International",
  "a-star": "Agency for Science, Technology and Research (A*STAR)",
  aisg: "AI Singapore",
  reviewer: "Reviewer",
  "admin-super": "Administrator (Superuser)",
  "admin-cluster": "Administrator (Cluster)",
  submitter: "User (Submission)",
  whitepaper: "Whitepaper",
  fullpaper: "Fullpaper",
  "whitepaper-s": "Whitepaper (Single Institution)",
  "whitepaper-m": "Whitepaper (Multiple Institutions)",
  "fullpaper-s": "Fullpaper (Single Institution)",
  "fullpaper-m": "Fullpaper (Multiple Institutions)",
  technology: "AI Technology",
  innovation: "AI Innovation",
  research: "AI Research",
};

export const RevMapper = {
  award: {
    strongly:
      "Yes. Strongly recommend for Full Proposal to be selected for award. Full Proposal is of outstanding quality and should be prioritised.",
    yes: "Yes. Recommend for Full Proposal to be selected for award.",
    no: "No. Do not recommend for Full Proposal to be selected for award.",
  },
  objectives: {
    5: "Excellent",
    4: "Good",
    3: "Average",
    2: "Poor",
    1: "Unable to Judge",
    0: "Not Applicable",
  },
  availability: {
    5: "Excellent",
    4: "Good",
    3: "Average",
    2: "Poor",
    1: "Unable to Judge",
    0: "Not Applicable",
  },
  significance: {
    5: "Excellent",
    4: "Good",
    3: "Average",
    2: "Poor",
    1: "Unable to Judge",
    0: "Not Applicable",
  },
  deployment: {
    5: "Excellent",
    4: "Good",
    3: "Average",
    2: "Poor",
    1: "Unable to Judge",
    0: "Not Applicable",
  },
  testPlans: {
    5: "Excellent",
    4: "Good",
    3: "Average",
    2: "Poor",
    1: "Unable to Judge",
    0: "Not Applicable",
  },
  team: {
    5: "Excellent",
    4: "Good",
    3: "Average",
    2: "Poor",
    1: "Unable to Judge",
    0: "Not Applicable",
  },
  outcomes: {
    5: "Excellent",
    4: "Good",
    3: "Average",
    2: "Poor",
    1: "Unable to Judge",
    0: "Not Applicable",
  },
  budget: {
    5: "Excellent",
    4: "Good",
    3: "Average",
    2: "Poor",
    1: "Unable to Judge",
    0: "Not Applicable",
  },
};

export const RevMapper_s2 = {
  award: {
    strongly:
      "Strongly recommend for Full Proposal to be selected for award. Full Proposal is of outstanding quality and should be prioritised",
    yes: "Recommend for Full Proposal to be selected for award",
    no: "Do not recommend for Full Proposal to be selected for award",
  },
  support_outcome: {
    support: "Supported",
    "not-support": "Not Supported",
  },
  scientific: {
    5: "Excellent",
    4: "Good",
    3: "Average",
    2: "Poor",
    1: "Unable to Judge",
    0: "Not Applicable",
  },
  novelty: {
    5: "Excellent",
    4: "Good",
    3: "Average",
    2: "Poor",
    1: "Unable to Judge",
    0: "Not Applicable",
  },
  feasibility: {
    5: "Excellent",
    4: "Good",
    3: "Average",
    2: "Poor",
    1: "Unable to Judge",
    0: "Not Applicable",
  },
  suitability: {
    5: "Excellent",
    4: "Good",
    3: "Average",
    2: "Poor",
    1: "Unable to Judge",
    0: "Not Applicable",
  },
  impact: {
    5: "Excellent",
    4: "Good",
    3: "Average",
    2: "Poor",
    1: "Unable to Judge",
    0: "Not Applicable",
  },
  overall_score: {
    4: "4 - Excellent: Proposal is of outstanding quality, I would like to highly recommend it to being funded.",
    3: "3 - Good: Proposal is of good quality, I think it could be funded.",
    2: "2 - Average: Proposal is lacking in some areas, but it has merits that outweigh its flaws. It can be considered after certain issues are addressed.",
    1: "1 - Poor: Proposal is lacking in one or more critical aspect, key issues are not sufficiently addressed. Its flaws outweigh its merits. Hence, it is insufficiently competitive and below the acceptance threshold.",
    0: "0 - Unable to Judge.",
  },
  manpower: {
    4: "Adequate",
    3: "Excessive",
    2: "Inadequate",
    1: "Unable to Judge",
  },
  equipment: {
    4: "Adequate",
    3: "Excessive",
    2: "Inadequate",
    1: "Unable to Judge",
  },
  other_operating: {
    4: "Adequate",
    3: "Excessive",
    2: "Inadequate",
    1: "Unable to Judge",
  },
  level_of_confidence: {
    4: "4 - Expert in the research area.",
    3: "3 - Familiar with the topic/research area.",
    2: "2 - Somewhat familiar with the topic/research area.",
    1: "1 - Not familiar with the topic/research area – can review for general AI topic of interest.",
  },
  relevance_of_proposal: {
    4: "4 – Very relevant to AI Core Technical Areas",
    3: "3 - Relevant to the AI Core Technical Areas",
    2: "2 - Somewhat relevant to the AI Core Technical Areas, but too domain specific.",
    1: "1 - Not relevant to the AI Core Technical Areas at all.",
  },
  potential_for_excellence: {
    4: "4 - Very relevant to One of the Three Themes",
    3: "3 - Relevant to One of the Three Themes",
    2: "2 - Somewhat relevant to One of the Three Themes, but too domain specific.",
    1: "1 - Not relevant to any One of the Three Themes at all.",
  },
};

/* Fullpaper */
export const fullpaper = {
  title: {
    elementType: "input-inline",
    elementConfig: {
      type: "text",
      placeholder: "enter a title for the proposal (max 200 characters)",
      isValid: false,
      isInvalid: false,
      required: true,
    },
    elementDecorators: {
      label: "[Cover Sheet] Title",
      sublabel: "",
      feedback: "valid",
      feedbackMsg: "",
    },
    validation: {
      isText: true,
      maxLength: 200,
      required: true,
    },
    value: "",
    valid: false,
    touched: false,
  },
  host_institution: {
    elementType: "select-inline",
    elementConfig: {
      options: [
        {
          value: "TBD",
          label: "<----- Please select from drop-down menu ----->",
        },
        {
          value: "a-star",
          label: "Agency for Science, Technology and Research (A*STAR)",
        },
        { value: "nus", label: "National University of Singapore (NUS)" },
        { value: "ntu", label: "Nanyang Technological University (NTU)" },
        { value: "smu", label: "Singapore Management University (SMU)" },
        {
          value: "sutd",
          label: "Singapore University of Technology and Design (SUTD)",
        },
      ],
      isValid: false,
      isInvalid: false,
      required: true,
    },
    elementDecorators: {
      label: "[Cover Sheet] Host Institution",
      sublabel: "",
      feedback: "valid",
      feedbackMsg: "",
      placeholders: [],
    },
    value: "TBD",
    validation: {
      required: true,
    },
    valid: false,
    touched: false,
  },
  total_budget: {
    elementType: "input-inline",
    elementConfig: {
      type: "text",
      placeholder: "(numerical only)",
      isValid: false,
      isInvalid: false,
      required: true,
    },
    elementDecorators: {
      label: "[Cover Sheet] Grand Total Cost (S$)",
      sublabel:
        "Direct + Indirect Research, including Research Scholarship if applicable",
      feedback: "valid",
      feedbackMsg: "",
    },
    validation: {
      isDecimal: true,
      maxLength: 10,
      required: true,
    },
    value: "",
    valid: false,
    touched: false,
  },
  project_duration: {
    elementType: "input-inline",
    elementConfig: {
      type: "text",
      placeholder: "maximum of 3.0 years",
      isValid: false,
      isInvalid: false,
      required: true,
    },
    elementDecorators: {
      label: "[Cover Sheet] Period of Support",
      sublabel: "In years. Maximum of 3.0 years.",
      feedback: "valid",
      feedbackMsg: "",
    },
    validation: {
      isDecimal: true,
      maxLength: 5,
      required: true,
      maxValue: 3.0,
    },
    value: "",
    valid: true,
    touched: false,
  },
  topic_areas: {
    // Reusing some terms that are confusing.
    elementType: "checkboxes-inline",
    elementConfig: {
      choices: [
        { value: "theme1", label: "LLMs with Limited Data" },
        { value: "theme2", label: "Frontier of LLM Methods" },
        { value: "theme3", label: "Multi-modal and Multi-lingual LLMs" },
        {
          value: "theme4",
          label: "Value Alignment and Trustworthiness of Generative AI",
        },
        { value: "theme5", label: "Training LLMs Without Sharing Data" },
      ],
      isValid: false,
      isInvalid: false,
    },
    elementDecorators: {
      label: "[Cover Sheet] Proposal Topic Area",
      sublabel: "",
      feedback: "valid",
      feedbackMsg: "",
    },
    value: [],
    validation: {
      minChoices: 1,
      required: true,
    },
    valid: false,
    touched: false,
  },
  team_information: {
    elementType: "input-cascade",
    elementConfig: {
      data: {
        header: {
          role: {
            label: "Role",
            sublabel: "",
            config: {
              type: "select",
              options: [
                // Please don't mess with the values here...
                { value: "", label: "(select a role from drop-down)" },
                { value: "pi", label: "Principal Investigator (PI)" },
                { value: "co-pi", label: "Co-Principal Investigator (Co-PI)" },
                { value: "collab", label: "Collaborator" },
              ],
              required: true,
            },
            value: "",
            valid: false,
            touched: false,
            validation: {
              required: true,
            },
            feedback: {
              type: "valid",
              message: "",
            },
          },
          firstname: {
            label: "First Name",
            sublabel: "",
            config: {
              type: "text",
              placeholder: "Given Name",
              required: true,
            },
            value: "",
            valid: false,
            touched: false,
            validation: {
              maxLength: 200,
              required: true,
            },
            feedback: {
              type: "valid",
              message: "",
            },
          },
          lastname: {
            label: "Last Name",
            sublabel: "",
            config: {
              type: "text",
              placeholder: "Family Name",
              required: true,
            },
            value: "",
            valid: false,
            touched: false,
            validation: {
              maxLength: 200,
              required: true,
            },
            feedback: {
              type: "valid",
              message: "",
            },
          },
          gscholar: {
            label: "Google Scholar",
            sublabel: "",
            config: {
              type: "text",
              placeholder: "(max 200 characters)",
              required: false,
            },
            value: "",
            valid: true,
            touched: false,
            validation: {
              maxLength: 200,
              required: false,
            },
            feedback: {
              type: "valid",
              message: "",
            },
          },
          dblp: {
            label: "DBLP",
            sublabel: "",
            config: {
              type: "text",
              placeholder: "(max 200 characters)",
              required: false,
            },
            value: "",
            valid: true,
            touched: false,
            validation: {
              maxLength: 200,
              required: false,
            },
            feedback: {
              type: "valid",
              message: "",
            },
          },
          institution: {
            label: "Institution",
            sublabel:
              "For PI's / Co-PI's institution: write in short-form only, e.g., A*STAR, NTU, NUS.",
            config: {
              type: "text",
              placeholder: "(max 200 characters)",
              required: true,
            },
            value: "",
            valid: false,
            touched: false,
            validation: {
              maxLength: 200,
              required: true,
            },
            feedback: {
              type: "valid",
              message: "",
            },
          },
          designation: {
            label: "Designation",
            sublabel: "",
            config: {
              type: "text",
              placeholder: "e.g Associate Professor",
              required: true,
            },
            value: "",
            valid: false,
            touched: false,
            validation: {
              maxLength: 200,
              required: true,
            },
            feedback: {
              type: "valid",
              message: "",
            },
          },
          department: {
            label: "Department",
            sublabel: "",
            config: {
              type: "text",
              placeholder: "(max 200 characters)",
              required: true,
            },
            value: "",
            valid: false,
            touched: false,
            validation: {
              maxLength: 200,
              required: true,
            },
            feedback: {
              type: "valid",
              message: "",
            },
          },
          percentage_effort: {
            label: "Percentage Effort %",
            sublabel:
              "Refers to % effort spent by the researcher in the project relative to his/her other team members. The total must add up to 100%.",
            config: {
              type: "text",
              placeholder: "(max 200 characters)",
              required: true,
            },
            value: "0",
            valid: false,
            touched: false,
            validation: {
              isDecimal: true,
              maxLength: 10,
              required: true,
            },
            feedback: {
              type: "valid",
              message: "",
            },
          },
          percentage_time: {
            label: "Percentage Time %",
            sublabel:
              "Refers to % effort spent by the researcher in the project relative to his/her other job scope(s). Note that the Lead PI is expected to commit at least 20% of his/her time and the Co-PI at least 10% of his/her time to this project.",
            config: {
              type: "number",
              placeholder: "(max 10 characters)",
              required: true,
            },
            value: "0",
            valid: false,
            touched: false,
            validation: {
              isDecimal: true,
              maxLength: 10,
              required: true,
            },
            feedback: {
              type: "valid",
              message: "",
            },
          },
        },
        content: [],
      },
      type: "stack",
      isValid: false,
      isInvalid: false,
    },
    elementDecorators: {
      label: "[Cover Sheet] Team Members",
      feedback: "valid",
      feedbackMsg: "",
      //sublabel: "There is a limit of 10 entries for this section. Please inform the admin if you need to input more entries."
    },
    value: [],
    validation: {
      required: true,
    },
    valid: true,
    touched: false,
  },
  proposal: {
    elementType: "upload",
    label: "[SECTION 1 to 10] Proposal",
    sublabel: "(Please only upload .pdf format)",
    feedback: "",
    value: "",
    valid: false,
    touched: false,
    loaded: true,
    validation: {
      required: true,
    },
  },
  budget: {
    elementType: "upload",
    label: "[SECTION 11] Budget Breakdown (max 16mb)",
    sublabel: "(Please only upload .xlsx format)",
    feedback: "",
    value: "",
    valid: true,
    touched: false,
    loaded: true,
    validation: {
      required: true,
    },
  },
  international_reviewers: {
    elementType: "input-cascade",
    elementConfig: {
      data: {
        header: {
          name: {
            label: "Name of Reviewer",
            sublabel: "",
            config: {
              type: "text",
              placeholder: "(max 200 characters)",
              required: true,
            },
            value: "",
            valid: false,
            touched: false,
            validation: {
              maxLength: 200,
              isText: true,
              required: true,
            },
            feedback: {
              type: "valid",
              message: "",
            },
          },
          institution: {
            label: "Institution",
            sublabel: "",
            config: {
              type: "text",
              placeholder: "(max 200 characters)",
              required: true,
            },
            value: "",
            valid: false,
            touched: false,
            validation: {
              maxLength: 200,
              required: true,
            },
            feedback: {
              type: "valid",
              message: "",
            },
          },
          designation: {
            label: "Designation",
            sublabel: "",
            config: {
              type: "text",
              placeholder: "e.g Associate Professor",
              required: true,
            },
            value: "",
            valid: false,
            touched: false,
            validation: {
              maxLength: 200,
              required: true,
            },
            feedback: {
              type: "valid",
              message: "",
            },
          },
          email: {
            label: "E-mail address",
            sublabel: "",
            config: {
              type: "text",
              placeholder: "e.g abc@xyz.com",
              required: true,
            },
            value: "",
            valid: false,
            touched: false,
            validation: {
              maxLength: 200,
              required: true,
            },
            feedback: {
              type: "valid",
              message: "",
            },
          },
          interest: {
            label: "Research interest / Expertise",
            sublabel: "",
            config: {
              type: "text",
              placeholder: "",
              required: true,
            },
            value: "",
            valid: false,
            touched: false,
            validation: {
              maxLength: 200,
              required: true,
            },
            feedback: {
              type: "valid",
              message: "",
            },
          },
          relationship: {
            label: "Relationship to PI / Co-PI",
            sublabel: "",
            config: {
              type: "text",
              placeholder: "",
              required: true,
            },
            value: "",
            valid: false,
            touched: false,
            validation: {
              maxLength: 200,
              required: true,
            },
            feedback: {
              type: "valid",
              message: "",
            },
          },
        },
        content: [],
      },
      type: "stack",
      isValid: false,
      isInvalid: false,
    },
    elementDecorators: {
      label: "[SECTION 12] Names of Suggested International Reviewers",
      sublabel: "Please nominate at least two(2) reviewers",
      feedback: "valid",
      feedbackMsg: "",
    },
    value: [],
    validation: {
      required: true,
    },
    valid: true,
    touched: false,
  },
  curriculum_vitae: {
    elementType: "upload",
    label:
      "[SECTION 13] CVs of all members (Lead PI, all Co-PIs, and Collaborators) (max 16mb)",
    sublabel: "(Please only upload .zip or .pdf format)",
    feedback: "",
    value: "",
    valid: true,
    touched: false,
    loaded: true,
    validation: {
      required: true,
    },
  },
  funding_support: {
    elementType: "upload",
    label: "[SECTION 14] Declaration Of Other Funding Support",
    sublabel: "(Please only upload .zip or .pdf format)",
    feedback: "",
    value: "",
    valid: true,
    touched: false,
    loaded: true,
    validation: {
      required: true,
    },
  },
  supplement_files: {
    elementType: "upload",
    //label: "[SECTION XV and XVI] Declaration by grant applicants and HI endorsement",
    label: "[SECTION 15] Declaration by grant applicants",
    sublabel: "(Please only upload .zip or .pdf format)",
    feedback: "",
    value: "",
    valid: true,
    touched: false,
    loaded: true,
    validation: {
      required: true,
    },
  },
};

export const s2_endorse = {
  endorse_file: {
    elementType: "file",
    elementConfig: {
      type: "file",
      isInvalid: false,
      required: true,
    },
    elementDecorators: {
      label: "[SECTION 16] Endorsement by Host Institution",
      sublabel: "(Please only upload .pdf format)",
      feedback: "valid",
      feedbackMsg: "",
    },
    validation: {
      isFile: true,
    },
    value: "",
    selectedFile: null,
    valid: false,
    touched: false,
  },
};
